/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./poppins-v20-latin/poppins-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./poppins-v20-latin/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./poppins-v20-latin/poppins-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./poppins-v20-latin/poppins-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./poppins-v20-latin/poppins-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./poppins-v20-latin/poppins-v20-latin-regular.svg#Poppins') format('svg');
  /* Legacy iOS */
}

body {
  margin: 0;
  height: 100vh;
  /* mobile viewport bug fix */
  max-height: -webkit-fill-available;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
